<template>
  <div>

    <div class="row">
      <div class="col-12 col-md-6">
        <label class="form__label">
          <span class="form__labelTitle">Quantity</span>
          <input type="text" class="form__input"
                 name="qty"
                 v-model="qty"

          >

<!--          v-validate="'required|numeric|min_value:1|max_value:10000'"-->
        </label>
      </div>
      <div class="col-12 col-md-6">
        <div class="row align-middle" v-if="currentItem.colour.id">
          <div class="col-6 align-middle">
            <p class="text_default">{{currentItem.colour.colour_name}} <span v-if="currentItem.is_same_as_frame"> (same as frame)</span></p>
            <p class="text_small" v-if="!currentItem.select_colour">(colour selection not available)</p>
          </div>
          <div class="col-6 align-middle"
               @click="openColourSelection()"
          >
            <div class="colours colour_box colours_small">
            <img class="box__img" :src="currentItem.colour.image" alt="color.colour_name" v-if="currentItem.colour.image">
            <p v-else-if="currentItem.colour.hexcode">
              <svg-icon name="palette" class="large_icon box__img" :style="{color: currentItem.colour.hexcode}"/>
            </p>
            <p v-if="!currentItem.colour.image && !currentItem.colour.hexcode">
              <svg-icon name="palette" class="large_icon box__img"/>
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="form__row row"
         v-for="(elem, key, index) in item.lengthVal"
         :key="index + key"
         v-if="currentItem.measure_type === 'LNTH'"
    >
      <div class="col-12 col-md-6">
      <label class="form__label">
        <span class="form__labelTitle">Length {{index+1}}<span class="text_small" v-if="isFrame"><br/>({{ elem.name }})</span></span>

        <select class="form__input"
                v-model="elem.l"
                :name="`length${key}`"
                @change="checkVal(elem)"
                :data-vv-scope="key"
        >
          <option v-for="(item, index) in lengthItem(elem)"
                  :value="item.code"
                  :key="index"
          >
            {{item.name}}
          </option>
        </select>
      </label>

      </div>

      <div class="col-12 col-md-3">
      <label class="form__label"
             v-if="order">
        <input type="text"
               class="form__input"
               :name="`value${key}`"
               v-model.number="elem.v"
               @input="checkDisabled(elem)"
               :disabled="elem.l === 'NONE'"

        >
<!--        v-validate="{required: true, numeric: true, max_value: currentItem.limits.max_length, min_value: currentItem.limits.min_length }"-->
        <span class="">mm</span>
      </label>
      </div>
      <div class="d-none d-md-block col-3"></div>
    </div>

    <div class="d-flex flex-column" v-if="currentItem.measure_type === 'SQR'">

      <div class="form__row order-2 row" :class="{width: isWidth || isAdmin()}">
        <div class="col-12 col-md-6">
          <label class="form__label">
            <span class="form__labelTitle">Width</span>
            <select class="form__input"
                    name="width"
                    v-model="item.lengthVal.l1.l"

                    @change="checkVal()"
            >
<!--              v-validate="'required'"-->
              <option value="NONE" selected disabled hidden>None</option>
              <option value="WDT">Width</option>
              <option value="CUST">Custom</option>

            </select>
          </label>

        </div>

        <div class="col-12 col-md-3">
        <label class="form__label" v-if="order">
          <input type="text" class="form__input"
                 name="value"
                 v-model="item.lengthVal.l1.v"

                 :disabled="item.lengthVal.l1.l !== 'CUST'"
          >
<!--          v-validate="{required: true, numeric: true, max_value: currentItem.limits.max_width, min_value: currentItem.limits.min_width }"-->
          <span class="">mm</span>
        </label>
        </div>
        <div class="d-none d-md-block col-3"></div>
      </div>

      <div class="form__row order-2 row" :class="{drop: !isWidth && !isAdmin()}">
        <div class="col-12 col-md-6">
        <label class="form__label">
          <span class="form__labelTitle">Drop</span>
          <select class="form__input"
                  name="drop"
                  v-model="item.lengthVal.l2.l"

                  @change="checkVal()"
          >
<!--            v-validate="'required'"-->
            <option value="NONE" selected disabled hidden>None</option>
            <option value="HTL">Drop</option>
            <option value="CUST">Custom</option>
          </select>
        </label>
        </div>
        <div class="col-12 col-md-3">
        <label class="form__label" v-if="order">
          <input type="text" class="form__input"
                 name="value"
                 v-model="item.lengthVal.l2.v"

                 :disabled="item.lengthVal.l2.l !== 'CUST'"
          >
<!--          v-validate="{required: true, numeric: true, max_value: currentItem.limits.max_length, min_value: currentItem.limits.min_length }"-->
          <span class="">mm</span>
        </label>
      </div>
      <div class="d-none d-md-block col-3"></div>
      </div>

    </div>


    <transition name="fade" mode="out-in">
      <select-colors v-show="selectColours"
      @onSelect="setColour"></select-colors>
    </transition>

    <!--<label class="form__label form__row" v-if="showMitreCut">-->
    <!--<span class="form__labelTitle">Mitre Cut</span>-->
    <!--<select class="form__input"-->
    <!--v-model="item.mitreCutValue"-->
    <!--name="mitreCut"-->
    <!--&gt;-->
    <!--<option value="" selected disabled hidden>No end treatment - Straight Cut lengths only</option>-->
    <!--<option :value="item.id"-->
    <!--v-for="item in mitreCut.data"-->
    <!--:key="item.id"-->
    <!--&gt;{{item.name}}-->
    <!--</option>-->
    <!--</select>-->
    <!--</label>-->

<!--    <ul v-if="!$parent.additionalAccessories">
      <li class="form__error" v-for="error  in filteredErrors">
        {{error}}
      </li>
      <li v-if="customErrorText.length" class="form__error">
        {{customErrorText}}
      </li>
    </ul>-->

    {{newEr}}0123

    <template v-if="!$parent.order">
      <button class="btn btn_default"
              type="button"
              @click="createAccessor"
              v-if="mode == 'new'"
      ><svg-icon name="solid/floppy-disk"></svg-icon> Save
      </button>

      <button class="btn btn_default"
              type="button"
              @click="updateAccessory"
              v-if="mode == 'edit'"
      ><svg-icon name="solid/floppy-disk"></svg-icon> Update
      </button>
    </template>

    <template v-else>
      <template v-if="actionLinksPortalTarget">
        <portal :to="actionLinksPortalTarget">
          <button class="btn btn_transparent"
                  type="button"
                  @click="Save"
          ><svg-icon name="solid/check"></svg-icon> Done
          </button>
        </portal>
      </template>
      <template v-else>
        <button class="btn btn_default"
                type="button"
                @click="Save"
        ><svg-icon name="solid/check"></svg-icon> Done
        </button>
      </template>
    </template>



  </div>
</template>

<script>
  import {mapGetters} from 'vuex';
  import {httpReq} from "../../../htttpReq";
  import {defineAsyncComponent} from "vue";
  const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));

  const frames = ['Build Out Frames', 'Door Sections', 'Window Sections', 'Fire Escape Section', 'Dual Slide Sections']

  import { useField, useForm } from "vee-validate";
  import * as yup from 'yup';

  export default {
    name: "AccessoriesSize",
    setup() {
     /* v-validate="{required: true, numeric: true, max_value: currentItem.limits.max_length, min_value: currentItem.limits.min_length }"*/
      const schema = yup.object({
        item : yup.object({
          qty: yup.number().typeError('The quantity field may only contain numeric characters').required().min(1).max(10000).label('Quantity'),
          lengthVal : yup.object({
            l1 : yup.object({
               v:yup.number().max(10)
            })
          })
        })
      });
     const errors = useForm({
        validationSchema: schema,
      });
      const { value: qty} = useField('item.qty');
      const { value: l1v} = useField('item.lengthVal.l1.v');
     let newEr = errors
      return {
        qty,
        newEr,
        l1v
      }
    },

    data() {
      return {
        showMitreCut: false,
        customError: false,
        customErrorText: '',
        isWidth: false,
        item: {
          mitreCutValue: '',
          lengthVal: {
            l1: {
              l: 'NONE',
              v: '',
              c: '',
              q: 1,
              code: 'HTL',
              name: 'Drop Left'
            },
            l2: {
              l: 'NONE',
              v: '',
              c: '',
              q: 1,
              code: 'WDT',
              name: 'Width Top'
            },
            l3: {
              l: 'NONE',
              v: '',
              c: '',
              q: 1,
              code: 'HTR',
              name: 'Drop Right'
            },
            l4: {
              l: 'NONE',
              v: '',
              c: '',
              q: 1,
              code: 'WDB',
              name: 'Width Bottom'
            },
          },
          qty: 1,
        },
        colorGroup: localStorage.getItem('colorGroup'),
        customRequired: false
      }
    },

      components: {
          SelectColors,
      },


    props: ['mitreCut', 'length', 'accessory', 'edit', 'order', 'type', 'package', 'actionLinksPortalTarget', 'styleName'],

    methods: {

      createAccessor() {
        this.$parent.accessory.mitreCut = this.item.mitreCutValue;
        this.$parent.accessory.lengthVal = this.item.lengthVal;
        this.$parent.accessory.qty = this.item.qty;

        if (this.customRequired && (this.type === 'LNTH' || this.type === 'SQR')) {
          if (this.type === 'SQR') {
            this.customErrorText = 'Both Width and Drop are required'
          }
          if (this.type === 'LNTH') {
            this.customErrorText = 'Please select at least one not "None" value for Length';
          }
          return false;
        }

       /* this.$validator.validate().then(valid => {
            if (valid) {*/
              this.$emit('create');
         /*   }
          }
        );*/


      },

      lengthItem(elem){
        let res = this.length.data

        if (frames.includes(this.styleName)) {
          return res.filter(i => {return ['NONE', elem.code, 'CUST'].includes(i.code)})
        }

        return res
      },

      Save() {

        if (this.customRequired && (this.currentItem.measure_type === 'LNTH' || this.currentItem.measure_type === 'SQR')) {
          if (this.currentItem.measure_type === 'SQR') {
            this.customErrorText = 'Both Width and Drop are required'
          }
          if (this.currentItem.measure_type === 'LNTH') {
            this.customErrorText = 'Please select at least one not "None" value for Length';
          }
          return false;
        }

        this.$validator.validate().then(valid => {
          if (valid) {

              this.$store.commit('accessories/currentItem', {
                 qty: this.item.qty,
                 length1: this.item.lengthVal.l1.v,
                length2: this.item.lengthVal.l2.v,
                length3: this.item.lengthVal.l3.v,
                length4: this.item.lengthVal.l4.v,
                  length1_type: this.item.lengthVal.l1.l,
                  length2_type: this.item.lengthVal.l2.l,
                  length3_type: this.item.lengthVal.l3.l,
                  length4_type: this.item.lengthVal.l4.l,
              });

              this.$store.dispatch('accessories/saveAccessory');
          }
        });
      },

        setColour(c){
            // let c = this.$store.getters['optionColours/selectedColour'];
            if (c.colour)
              this.$store.commit('accessories/checkAccessory', {
                colour: c.colour,
                is_same_as_frame: c.is_same_as_frame
              })
        },

      updateAccessory() {
        this.customError = false;
        if (this.customRequired && (this.type === 'LNTH' || this.type === 'SQR')) {
          if (this.type === 'SQR') {
            this.customErrorText = 'Both Width and Drop are required'
          }
          if (this.type === 'LNTH') {
            this.customErrorText = 'Please select at least one not "None" value for Length';
          }
          return false;
        }

        this.$validator.validate().then(valid => {
          if (valid) {
            this.$emit('update', this.item);
          }
        });
      },

      closeUpdate() {
        this.$parent.editAccessory = false;
        this.$emit('close');
      },

      back() {
        this.$store.commit('accessories/currentItem', { grade: 0 });
      },

      checkDisabled(elem) {
        let item = this.$store.getters['createdOrder/createdOrder'];
        if (elem.v !== item.width || elem.v !== item.drop) {
          elem.l = 'CUST';
        }
        else return false;
      },

      checkLength() {
        let count = 0;
        for (let key in this.item.lengthVal) {

          if (this.item.lengthVal[key].l !== '' && this.item.lengthVal[key].l !== "NONE") {
            count++;

            if (count >= 2 && this.mitreCut) {
              this.showMitreCut = true;
              this.validate = false
            }

            else {
              this.showMitreCut = false;
            }
          }
        }
      },

      checkVal() {
        //return
        let item = this.$store.getters['orderPosition/orderPosition'];
          for (let k in this.item.lengthVal) {

              if (typeof(this.item.lengthVal[k].l) != 'undefined' && this.item.lengthVal[k].l !== 'CUST' )
                this.item.lengthVal[k].v = item[this.item.lengthVal[k].l.toLowerCase()];

            //perimeter
            if (this.item.lengthVal[k].l === 'PRM') {
              this.item.lengthVal[k].v = Number(item.htl) + Number(item.wdt) + Number(item.htr) + Number(item.wdb);
            }

        }

      },

      checkCustom() {
        for (let k in this.item.lengthVal) {
          if (this.item.lengthVal[k].l === 'CUST') {
            this.item.lengthVal[k].v = this.accessory.lengthVal[k].v;
          }
        }
      },

      updateAccessoryOnCreate() {
        this.$store.dispatch('accessories/getLength');
        this.item.qty = (this.currentItem.qty === '' || _.isNull(this.currentItem.qty)) ? '' : this.currentItem.qty;

        this.item.lengthVal.l1['l'] = this.currentItem.length1_type || 'NONE';
        this.item.lengthVal.l1['v'] = this.currentItem.length1;
        this.item.lengthVal.l2['l'] = this.currentItem.length2_type || 'NONE';
        this.item.lengthVal.l2['v'] = this.currentItem.length2;
        this.item.lengthVal.l3['l'] = this.currentItem.length3_type || 'NONE';
        this.item.lengthVal.l3['v'] = this.currentItem.length3;
        this.item.lengthVal.l4['l'] = this.currentItem.length4_type || 'NONE';
        this.item.lengthVal.l4['v'] = this.currentItem.length4;

        this.item.limits = this.currentItem.limits;
      },

      customRequiredChecked() {
        let value = [];
        for (let k in this.item.lengthVal) {
          if (this.item.lengthVal[k].l !== 'NONE') {
            value.push(this.item.lengthVal[k].l);
          }
        }
        if (this.type === 'LNTH' && value.length > 0) {
          this.customErrorText = '';
          return this.customRequired = false;
        }
        if (this.type === 'SQR' && value.length > 1) {
          this.customErrorText = '';
          return this.customRequired = false;
        }
        else {
          return this.customRequired = true;
        }
      },

        openColourSelection(){
        this.showBackButton = true
          if (!this.currentItem.select_colour)
              return;

            if (this.currentItem.colour_group)
                this.$store.commit('optionColours/colourGroup', this.currentItem.colour_group);

            this.$store.commit('optionColours/openModal', true);
            this.$store.commit('optionColours/getColors');
        },


    },

    computed: {
      ...mapGetters({
        colors: 'accessories/colors',
          mode : 'accessories/mode',
          selectColours: 'optionColours/openModal',
      }),

      currentItem() {
          return this.$store.getters['accessories/currentItem'];

      }  ,

      isFrame(){
        return frames.includes(this.styleName)
      },

      filteredErrors() {
       /* let control = {};
        this.errors.items.forEach(el => {
          control[el.msg] = true;
        });

        return _.keys(control);*/
      },
    },

    created() {
      this.updateAccessoryOnCreate();

      this.checkLength();

      this.checkVal();

      this.checkCustom();

      this.$store.dispatch('accessories/getLength');

      if (!this.package) {
        this.$store.dispatch('accessories/getColorsGroup');
      }

      httpReq.get('user/profile').then(response => {
        if (response.data.prefer_entry === 'width') {
          this.isWidth = true;
        }
      })
    },

      unmounted(){
        // this.$store.dispatch('accessories/cleanup');
      },

    updated() {
      this.checkLength;
    }
    ,
  }
</script>

<style scoped lang="scss">
  @import '../../../assets/scss/utils/vars';

  .form {

    &__input {
      border: none;
      border-bottom: 2px solid $navy;
      &:disabled {
        opacity: .6;
      }

    }

    &__labelTitle {
      color: $navy;
      width: calc(15% - 20px);
      min-width: 80px;
      flex: 1 1 100%;
    }

    &__error {
      position: absolute;
      bottom: -10px
    }

    &__label {
      display: flex;
      align-items: center;
      flex: 1 1 100%;
      margin: 15px 5px;
      width: auto;
    }

    &__row {
      display: flex;
      align-items: center;
      position: relative;
      padding-bottom: 10px;
    }
  }

  .btn {
    margin: 20px 0 0;
    max-width: none;
  }

  .modal__close {
    color: $green;
    top: 0.7rem;
    z-index: 999;
  }

  .form__error {
    position: static;
  }

  .form__error.form__error_additional {
    position: absolute;
  }

  .drop {
    order: 1;
  }

  .width {
    order: 1;
  }

  @media screen and (max-width: 576px) {
    .form {
      &__row {
        flex-wrap: wrap;
        border-bottom: 2px solid $navy;
      }
      &__label {
        &:nth-child(1) {
          flex: 1 1 100%;
        }
      }
    }
  }
</style>
